import manageModalAdd from '@/components/manage-modal-add/index.vue'

export default {
    name: 'AuthAdd',

    components: {
        manageModalAdd
    },

    mounted () {
        if (this.$route.query.type) this.roleId = this.$route.query.type;
        this.handleGetTmpl();
    },

    methods: {
        // 获取授权模板数据
        handleGetTmpl () {
            let options = {};
            if (this.$route.query.staff_id) options.auth_ids = this.$route.query.staff_id;
            if (this.loading) return;
            this.loading = true;
            this.$Message.loading({content: '请求中...', duration: 0})
            this.$api.auth.authTmpl(options).then(res => {
                this.loading = false;
                this.$Message.destroy()
                if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})
                this.categoryItems = res.obj.category_list;
                this.roleItems = res.obj.role_list;

                res.obj.role_list.map((v, i) => {
                    if (i == 0 || v.is_select == 1) this.roleId = v.role_id;
                })

                // 选中员工
                res.obj.staff_list.map(v => {
                    this.select_obj.push({id: v.staff_id, real_name: v.staff_name});
                    this.select_array.push(v.staff_id)
                    this.check_array.push(v.staff_id)
                })
            }).catch( err => {
                this.loading = false;
                this.$Message.destroy()
                console.log(err)
            })
        },

        // 获取部门列表
        handleGetDepartments () {
            if (this.loading) return;
            this.loading = true;
            this.$Message.loading({content: '请求中...', duration: 0})
            this.$api.auth.departmentList().then(res => {
                if (res.status_code != 0) {
                    this.loading = false;
                    this.$Message.destroy()
                    this.$Message.warning({content: res.status_msg})
                    return
                }
                this.departmentItems = res.list;
                this.handleGetRoles();
            }).catch( err => {
                this.loading = false;
                this.$Message.destroy()
                console.log(err)
            })
        },

        // 确定授权员工
        handleEventSuccess (obj) {
            this.select_array = this.select_array.concat(obj.arr)
            this.check_array = this.check_array.concat(obj.arr)
            this.select_obj = this.select_obj.concat(obj.objs)
        },

        // 确定提交
        handleSubmit () {
            if (this.loading) return;
            if (this.select_obj.length <= 0) return this.$Message.warning({content: '请选择授权对象'})
            if (!this.categoryItems.map( v => v.is_select).includes(1)) return this.$Message.warning({content: '请选择文档功能'})
            this.$Modal.confirm({
                title: '提示',
                content: '是否确定授权？',
                onOk: () => {
                    this.handleSubmitAjax();
                }
            })
        },
        handleSubmitAjax () {
            
            let roleItems = this.roleItems.map(v => {
                v.is_select = v.role_id == this.roleId ? 1 : 0;
                return v;
            })
            let staff_list = [];
            this.select_obj.map(v => {
                staff_list.push({"staff_id": v.id,"staff_name": v.real_name})
            })
            this.loading = true;
            this.$api.auth.staffAuth({
                staff_list: staff_list,
                role_list: roleItems,
                category_list: this.categoryItems,
            }).then(res => {
                this.loading = false;
                this.$Message.destroy()
                if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})
                this.$Modal.confirm({
                    title: '提示',
                    content: '授权成功',
                    okText: '继续添加员工授权',
                    cancelText: '关闭',
                    onOk: () => {
                        this.$router.push({name: 'AuthAdd'})
                        this.select_obj = [];
                        this.select_array = [];
                        this.check_array = [];
                        this.handleGetTmpl();
                    },
                    onCancel: () => {
                        this.$router.push({name: 'Auth'})
                    }
                })  
            }).catch( err => {
                this.loading = false;
                this.$Message.destroy()
                console.log(err)
            })
        },


        // 权限选择
        handleChange (value, type, index, pIndex, rIndex) {
            switch (type) {
                case 0:
                    this.categoryItems[index].is_select = value;
                    this.handleSelectFun(value, this.categoryItems[index].second_item, 1)
                break;
                case 1:
                    this.categoryItems[pIndex].is_select = value;
                    this.categoryItems[pIndex].second_item[index].third_item.map(v => {
                        v.is_select = value;
                    })
                    if (value == 0) {
                        this.categoryItems[pIndex].second_item.map(v => {
                            if (v.is_select == 1) this.categoryItems[pIndex].is_select = 1
                        })
                    }
                break;
                case 2:
                    this.categoryItems[rIndex].is_select = value;
                    this.categoryItems[rIndex].second_item[pIndex].is_select = value;
                    this.categoryItems[rIndex].second_item[pIndex].third_item[index].is_select = value;
                    if (value == 0) {
                        this.categoryItems[rIndex].second_item[pIndex].third_item.map(v => {
                            if (v.is_select == 1) {
                                this.categoryItems[rIndex].second_item[pIndex].is_select = 1
                            }
                        })
                        this.categoryItems[rIndex].second_item.map(v => {
                            if (v.is_select == 1) this.categoryItems[rIndex].is_select = 1
                        })
                    }
                break;
            }
        },
        // 递归选择函数
        handleSelectFun (value, items, index) {
            items.map((v, i) => {
                v.is_select = value;
                if (index == 0 && v.second_item.length > 0) this.handleSelectFun(value, v.second_item, 1)
                if (index == 1 && v.third_item.length > 0) this.handleSelectFun(value, v.third_item, 2)
            })
        }
    },

    data() {
        return {
            loading: false,
            catItems: [
                {name: '录入档案', id: 0},
                {name: '处理调档', id: 1},
                {name: '检索及报表', id: 2},
                {name: '档案提取', id: 3}
            ],

            // 设置员工/部门
            check_array: [],
            select_array: [],
            select_obj: [],
            isSetManage: false,

            departmentItems: [],
            departmentItem: {},

            categoryItems: [],

            roleId: 0,
            roleItem: {},
            roleItems: [],
        }
    }
}