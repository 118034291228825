<template lang="pug">

    //- 添加成员
    Modal(
        title="添加员工"
        width="606"
        :value="isModal"
        :mask-closable="false"
        :closable="false"
        footer-hide
    )
       
        .manage
            .manage-content.clear
                .manage-box.left
                    .hd 选择
                    .box 
                        .search
                            Input(prefix="ios-search" placeholder="搜索" v-model="search")
                        .breadcrumb
                            a(v-if="department.level > 0", @click="search = '';handleSelectDepartment(departmentItems[0], department.father_id)") <返回{{department.name}}
                            span(v-else) 当前部门：{{department.name}}
                        .content
                            .loop_file
                                .item(v-for="(v, i) in department.arr_child" :key="v.id" v-if="v.name.indexOf(search) >= 0")
                                    i.icon.icon-wenjianjia
                                    span(v-if="v.is_child || v.dept_itself_staff_count > 0" @click="handleCompanyStaffList(v)") {{v.name}}
                                    span(v-else) {{v.name}}
                                    a.c(v-if="v.is_child || v.dept_itself_staff_count > 0" @click="handleCompanyStaffList(v)")
                                        i.icon.icon-bumen
                                        | 下级
                                .item(v-for="(v, i) in companyItems" :key="v.id" v-if="v.real_name.indexOf(search) >= 0")
                                    Checkbox(@on-change="handleChangeCheckbox(v)" :value="select_array.indexOf(v.id) >= 0" :disabled="(select_array.indexOf(v.id) >= 0 || check_array.indexOf(Number(v.id)) >= 0)")
                                    i.icon.icon-moban2_hongse
                                    span(@click="handleChangeCheckbox(v)") {{v.real_name}}
                                    
                .manage-box.right
                    .hd 已选
                    .box 
                        .content
                            .loop_select
                                .item(v-for="(v, i) in select_objs", :key="i")
                                    i.icon.icon-wenjianjia(v-if="v.name")
                                    i.icon.icon-moban2_hongse(v-else)
                                    span {{v.name || v.real_name}}
                                    a(@click="handleChangeCheckbox(v, true)")
                                        i.icon.icon-shanchu2
                .manage-footer
                    Button(type="primary", @click="handleOnOk") 确定
                    Button(type="default", @click="$emit('input', false)") 取消
</template>

<script>

export default {
    name: 'manageModalAdd',

    props: {
        value: {
            type: Boolean,
            default: false
        },
        check_array: {          // 原先选中角色
            type: Array,
            default: []
        }
    },

    watch: {
        'value' (val) {
            if (val) {
                this.handleDepartment()
            } else {
                this.isModal = false;
                this.department = []
                this.companyItems = [];
                this.select_array = [];
                this.select_objs = [];
            }
        }
    },

    methods: {
        // 获取部门列表
        handleDepartment () {
            if (this.loading) return;
            this.loading = true 
            this.$Message.loading({content: '请求中...', duration: 0})
            this.$api.auth.departmentList().then(res => {
                this.loading = false
                if (res.status_code != 0) {
                    this.$Message.destroy()
                    this.$Message.warning({content: res.status_msg})
                    return
                }
                this.departmentItems = res.list
                this.handleCompanyStaffList(this.departmentItems[0])
            }).catch(err => {
                this.loading = false
                this.$Message.destroy()
                console.log(err)
            })
        },
        // 获取部门人员列表
        handleCompanyStaffList(obj) {
            if (this.loading) return;
            this.loading = true 
            this.$api.auth.staffList({
                level: obj.level,
                is_all: 0,
                arr_dept_id: [obj.id]
            }).then(res => {
                this.loading = false
                this.$Message.destroy()
                if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})
                this.companyItems = res.list
                this.department = obj
                this.isSetManage = true
                this.isModal = true
            }).catch(err => {
                this.loading = false
                this.$Message.destroy()
                console.log(err)
            })
        },
        handleSelectDepartment (item, id) {
            let array = item.arr_child;
            if (item.id == id) {
                this.department = item
                this.handleCompanyStaffList(item)
                return
            } else {
                for (let i = 0; i < array.length; i++) {
                    let v = array[i];
                    if (v.arr_child.length <= 0) return;
                    this.handleSelectDepartment(v, id)
                }
            }
        },

        // 选择部门/员工
        handleChangeCheckbox (obj, check = false) {
            let id = obj.id; 
            if (this.check_array.indexOf(id) >= 0) return;
            if (this.select_array.indexOf(id) >= 0 && !check) return;
            let index = this.select_array.indexOf(id);
            if (index >= 0) {
                this.select_objs.splice(index, 1)
                this.select_array.splice(index, 1)
            } else {
                this.select_objs.push(obj)
                this.select_array[this.select_array.length] = id
            }
        },

        // 确定
        handleOnOk () {
            let name = this.select_objs.map(v => v.real_name).join(',')
            this.$emit('event-success', {arr: this.select_array, name: name, objs: this.select_objs});
            this.$emit('input', false)
        }
    },

    data () {
        return {
            loading: false,
            isModal: false,
            search: '',
            department: [],
            departmentItems: [],
            companyItems: [],

            select_array: [],
            select_objs: [],
        }
    }
}
</script>
<style lang="less">
// 设置管理员
.manage                                 {
    &-content                           {margin: -10px 0;}
    &-box                               {width: 278px;
        .hd                             {color: #455A64;font-size: 14px;line-height: 32px;}
        .box                            {border: 1px solid #D9D9D9;border-radius: 4px;width: 278px;height: 340px;padding: 6px 10px;position: relative;
            .search                     {position: absolute;top: 6px;left: 10px;width: 258px;}
            .breadcrumb                 {position: absolute;top: 46px;left: 10px;z-index: 10;text-overflow:ellipsis;white-space: nowrap;overflow: hidden;width: 258px;
                a                       {color: #2d8cf0;font-size: 14px;}
                span                    {color: #666;font-size: 14px;}
            }
            .content                    {height: 100%;overflow: auto;margin: 0 -10px;
                &::-webkit-scrollbar        {width: 4px;height: 8px;}
                &::-webkit-scrollbar-thumb  {border-radius: 10px;background: #ccc;}
                &::-webkit-scrollbar-track  {background: none;}
                .loop_select            {padding: 0 10px;
                    .item               {height: 24px;line-height: 24px;font-size: 14px;color: #455A64;padding-right: 20px;position: relative;
                        .icon       {color: #999;font-size: 12px;}
                        .icon-wenjianjia{color: #2d8cf0;}
                        .icon-moban2_hongse{font-size: 14px;color: #2d8cf0;}
                        span            {margin-left: 4px;}
                        a               {
                            i           {color: #ccc;font-size: 14px;position: absolute;right: 0;}
                        }
                    }
                }
                .loop_file              {padding: 0 10px;
                    .item               {line-height: 24px;font-size: 14px;color:#455A64;cursor: pointer;padding-right: 20px;position: relative;
                        .icon       {color: #999;font-size: 12px;}
                        .icon-wenjianjia{color: #2d8cf0;}
                        .icon-moban2_hongse{font-size: 14px;color: #2d8cf0;}
                        em              {font-style: normal;color:#FFF;display: inline-block;border-radius: 50%;overflow: hidden;font-size: 12px;background: #2d8cf0;width: 16px;height: 16px;line-height: 16px;text-align: center;position: relative;top: 2px;}
                        span            {margin-left: 4px;}
                        a               {position: absolute;right: 0;top: 2px;color: #2d8cf0;font-size: 12px;
                            .icon   {margin-right: 2px;font-size: 12px;color: #2d8cf0;}
                        }
                    }
                }
            }
        }
        &.left                          {float: left;
            .box                        {padding-top: 68px;}
        }
        &.right                         {float: right;}
    }
    &-footer                            {padding-top: 22px;text-align: center;width: 100%;clear: both;
        button                          {margin: 0 4px;}
    }
}
</style>